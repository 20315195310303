import { useEffect, useState } from "react";
import './App.scss';
import logo from "./images/pls.png";

import eye1 from "./images/eye/eye-1.jpg";
import eye2 from "./images/eye/eye-2.jpg";
import eye3 from "./images/eye/eye-3.jpg";
import eye4 from "./images/eye/eye-4.jpg";

import love1 from "./images/love/love-1.jpg";
import love2 from "./images/love/love-2.jpg";
import love3 from "./images/love/love-3.jpg";
import love4 from "./images/love/love-4.jpg";
import love5 from "./images/love/love-5.jpg";
import love6 from "./images/love/love-6.jpg";
import love7 from "./images/love/love-7.jpg";
import love8 from "./images/love/love-8.jpg";
import love9 from "./images/love/love-9.jpg";
import love10 from "./images/love/love-10.jpg";
import love11 from "./images/love/love-11.jpg";

import my1 from "./images/my/my-1.jpg";
import my2 from "./images/my/my-2.jpg";
import my3 from "./images/my/my-3.jpg";
import my4 from "./images/my/my-4.jpg";

import mommy1 from "./images/mommy/mommy-1.jpg";
import mommy2 from "./images/mommy/mommy-2.jpg";
import mommy3 from "./images/mommy/mommy-3.jpg";
import mommy4 from "./images/mommy/mommy-4.jpg";
import mommy5 from "./images/mommy/mommy-5.jpg";
import mommy6 from "./images/mommy/mommy-6.jpg";
import mommy7 from "./images/mommy/mommy-7.jpg";
import mommy8 from "./images/mommy/mommy-8.jpg";

function App() {
  const eyes = [
    eye1,
    eye2,
    eye3,
    eye4
  ];

  const loves = [
    love1,
    love2,
    love3,
    love4,
    love5,
    love6,
    love7,
    love8,
    love9,
    love10,
    love11,
  ];

  const mys = [
    my1,
    my2,
    my3,
    my4
  ];

  const mommies = [
    mommy1,
    mommy2,
    mommy3,
    mommy4,
    mommy5,
    mommy6,
    mommy7,
    mommy8
  ];

  let [eyeIndex, setEyeIndex] = useState(1);
  let [loveIndex, setLoveIndex] = useState(1);
  let [myIndex, setMyIndex] = useState(1);
  let [mommyIndex, setMommyIndex] = useState(1);  
 
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  useEffect(() => {                  
    const eyeIntervalId = setInterval(() => {            
      setEyeIndex(getRandomInt(1, eyes.length));              
    }, 3500);    

    return () => {
      clearInterval(eyeIntervalId);
    }
  }, [eyes.length]);

  useEffect(() => {                  
    const loveIntervalId = setInterval(() => {            
      setLoveIndex(getRandomInt(1, loves.length));              
    }, 3500);    

    return () => {
      clearInterval(loveIntervalId);
    }
  }, [loves.length]);

  useEffect(() => {                  
    const myIntervalId = setInterval(() => {            
      setMyIndex(getRandomInt(1, mys.length));              
    }, 10000);    

    return () => {
      clearInterval(myIntervalId);
    }
  }, [mys.length]);

  useEffect(() => {                  
    const mommyIntervalId = setInterval(() => {            
      setMommyIndex(getRandomInt(1, mommies.length));              
    }, 4500);    

    return () => {
      clearInterval(mommyIntervalId);
    }
  }, [mommies.length]);

  return (
    <div id="app">
      <header>
        <img src={logo} alt="Pookie Love Smoochie Logo" />
        <h1>Pookie Love Smoochie</h1>
      </header>
      <section id="content">
        <h2>Happy Mother's Day!</h2>
        <p>A website dedicated to the most dedicated mother. Both her son and her husband love her very much, and wish her a wonderful Monther's Day!</p>
        <div id="photo-wrapper">
          <div className="photo" id="eye" style={{ 
            backgroundImage: `url(${eyes[eyeIndex]})` 
          }}>          
          </div>
          <div className="photo" id="love"  style={{ 
            backgroundImage: `url(${loves[loveIndex]})` 
          }}>
            
          </div>
          <div className="photo" id="my" style={{ 
            backgroundImage: `url(${mys[myIndex]})` 
          }}>
            
          </div>
          <div className="photo" id="mommy" style={{ 
            backgroundImage: `url(${mommies[mommyIndex]})` 
          }}>
          
          </div>
        </div>        
      </section>
    </div>
  );
}

export default App;
